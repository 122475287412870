<template>
  <div class="box">
    <label class="checkbox has-text-weight-bold has-margin-bottom">
      <input
        type="checkbox"
        @change="updateYieldPlayground"
        v-model="yieldSettingsPlayground.ApplyLocationUtilization"
      />
      Location utilization
    </label>
    <span class="has-margin-left">
      <a @click="setShowInfo">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'info-circle']" />
        </span>
      </a>
    </span>

    <div v-if="showInfo">
      <article class="message is-info is-small">
        <div class="message-body">
          When activated will apply a discount or a surcharge based on the
          utilization of the location on the selected day / date
        </div>
      </article>
    </div>

    <div
      v-if="
        yieldSettingsPlayground.ApplyLocationUtilization &&
          mShowBox &&
          !showInfo
      "
    >
      <table
        v-if="
          yieldSettingsPlayground.ApplyLocationUtilization &&
            yieldSettingsPlayground.MeetingtypeId === 1
        "
        class="table is-fullwidth is-narrow"
      >
        <tbody>
          <tr v-if="yieldSettingsPlayground.ApplyLocationUtilization">
            <td width="6" class="is-size-7">0%</td>
            <td>
              <vue-slider
                v-model="locationUtilizationValues"
                :min="0"
                :max="200"
                :enableCross="false"
                :interval="1"
                :tooltip-formatter="formatter"
                @change="updateYieldSettingsLocationUtilizationValues"
                @drag-end="updateYieldPlayground"
              />
            </td>
            <td width="6" class="is-size-7">100%</td>
          </tr>
        </tbody>
      </table>

      <article class="message is-info is-small">
        <div class="message-body">
          <div>
            0% occupancy apply
            {{
              roundToZero(
                yieldSettingsPlayground.LocationUtilizationBottomValue * 100 -
                  100
              )
            }}% discount
          </div>
          <div>
            100% occupancy apply
            {{
              roundToZero(
                yieldSettingsPlayground.LocationUtilizationTopValue * 100 - 100
              )
            }}% surcharge
          </div>
        </div>
      </article>
    </div>

    <div
      v-if="yieldSettingsPlayground.ApplyLocationUtilization"
      class="has-text-centered has-margin-top"
    >
      <a @click="mShowBox = !mShowBox" class="button is-small is-rounded">
        <span v-if="mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-up']" />
        </span>
        <span v-if="!mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    VueSlider
  },

  props: {
    showBox: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      formatter: v => `${v - 100}%`,
      mShowBox: this.showBox,
      showInfo: false
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground'])
  },

  watch: {
    showBox: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.mShowBox = newVal
      }
    }
  },

  created() {
    this.locationUtilizationValues = this.getLocationUtilizationValues()
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),

    setShowInfo() {
      this.showInfo = !this.showInfo
      if (this.showInfo) {
        this.mShowBox = true
      }
    },

    updateYieldPlayground() {
      this.setYieldSettingsPlayground(this.yieldSettingsPlayground)
      this.$emit('yieldSettingsUpdated')
    },

    getLocationUtilizationValues() {
      return [
        this.roundToZero(
          this.yieldSettingsPlayground.LocationUtilizationBottomValue * 100
        ),
        this.roundToZero(
          this.yieldSettingsPlayground.LocationUtilizationTopValue * 100
        )
      ]
    },

    updateYieldSettingsLocationUtilizationValues() {
      let self = this

      let bottomValue = self.locationUtilizationValues[0] / 100
      let topValue = self.locationUtilizationValues[1] / 100

      self.yieldSettingsPlayground.LocationUtilizationBottomValue = bottomValue
      self.yieldSettingsPlayground.LocationUtilizationTopValue = topValue
    },

    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    }
  }
}
</script>
